export const closeIcon = 'https://s3.21bits.io/front_static/assets/images/chat/close.png';
export const footSimple = 'https://s3.21bits.io/front_static/assets/images/web/footSimple.png';
export const footVerified = 'https://s3.21bits.io/front_static/assets/images/web/foot_verified.png';
export const footLisence = 'https://s3.21bits.io/front_static/assets/images/newIcon/license.png';
export const logo = 'https://s3.21bits.io/front_static/assets/images/newIcon/logo.png';
export const verify_icon1 = 'https://s3.21bits.io/front_static/assets/images/web/verify_icon1.png';
export const verify_icon2 = 'https://s3.21bits.io/front_static/assets/images/web/verify_icon2.png';
export const chatIcon = 'https://s3.21bits.io/front_static/assets/images/chat/chatIcon.png';
export const BNB = 'https://s3.21bits.io/front_static/assets/images/wallet/BNB.png';
export const USDT = 'https://s3.21bits.io/front_static/assets/images/wallet/USDT.png';
export const USDC = 'https://s3.21bits.io/front_static/assets/images/wallet/USDC.png';
export const TRUMP = 'https://s3.21bits.io/front_static/assets/images/wallet/TRUMP.png';

export const ETH = 'https://s3.21bits.io/front_static/assets/images/wallet/ETH.png';
export const BTC = 'https://s3.21bits.io/front_static/assets/images/wallet/BIT.png';
export const SOL = 'https://s3.21bits.io/front_static/assets/images/wallet/SOL.png';
export const LTC = 'https://s3.21bits.io/coinlist/LTC.png';

export const lock = 'https://s3.21bits.io/front_static/assets/images/loginVerify/lock.svg';
export const fullScreen = 'https://s3.21bits.io/front_static/assets/images/loginVerify/fullScreen1.svg';
export const WALLET = 'https://s3.21bits.io/front_static/assets/images/svgIcon/wallet_grey.svg';

export const gift = 'https://s3.21bits.io/front_static/assets/images/svgIcon/gift_color.svg';
export const blueBox = 'https://s3.21bits.io/front_static/assets/images/claim/blue_box.png';
export const redBox = 'https://s3.21bits.io/front_static/assets/images/claim/red_box.png';
export const yellowBox = 'https://s3.21bits.io/front_static/assets/images/claim/yellow_box.png';
export const greenBox = 'https://s3.21bits.io/front_static/assets/images/claim/green_box.png';
export const coin = 'https://s3.21bits.io/front_static/assets/images/claim/Coin.png';
export const giftBlack = 'https://s3.21bits.io/front_static/assets/images/svgIcon/gift_black.svg';

export const lock_black_icon = 'https://s3.21bits.io/front_static/assets/images/newIcon/Lock_black_icon.png';

export const casino_yellow = 'https://s3.21bits.io/front_static/assets/images/svgIcon/Casino_icon_on.svg';
export const casino = 'https://s3.21bits.io/front_static/assets/images/svgIcon/Casino_icon.svg';
export const trade = 'https://s3.21bits.io/front_static/assets/images/svgIcon/Trade_icon.svg';
export const trade_yellow = 'https://s3.21bits.io/front_static/assets/images/svgIcon/Trade_icon-1.svg';
export const sport = 'https://s3.21bits.io/front_static/assets/images/svgIcon/Sport_icon.svg';
export const sport_yellow = 'https://s3.21bits.io/front_static/assets/images/svgIcon/Sport_icon-1.svg';
export const airdrop = 'https://s3.21bits.io/front_static/assets/images/svgIcon/Airdrop_icon.svg';
export const raceOn = 'https://s3.21bits.io/front_static/assets/images/svgIcon/raceOn.svg';
export const raceOff = 'https://s3.21bits.io/front_static/assets/images/svgIcon/raceOff.svg';
export const airdrop_yellow = 'https://s3.21bits.io/front_static/assets/images/svgIcon/Airdrop_icon-1.svg';

export const User1 = 'https://s3.21bits.io/front_static/assets/images/users/user-round.svg';

export const vip = 'https://s3.21bits.io/front_static/assets/images/svgIcon/VIP_icon.svg';
export const vip_blue = 'https://s3.21bits.io/front_static/assets/images/svgIcon/VIP_icon-1.svg';
export const Logout = 'https://s3.21bits.io/front_static/assets/images/svgIcon/Logout.svg';
export const Logout_blue = 'https://s3.21bits.io/front_static/assets/images/svgIcon/LOGOUT_icon.svg';
export const Vector = 'https://s3.21bits.io/front_static/assets/images/svgIcon/Referrals_icon.svg';
export const Vector_blue = 'https://s3.21bits.io/front_static/assets/images/svgIcon/Referrals_icon-1.svg';
export const transactions = 'https://s3.21bits.io/front_static/assets/images/svgIcon/Transactions_icon.svg';
export const transactions_blue = 'https://s3.21bits.io/front_static/assets/images/svgIcon/Transactions_icon-1.svg';
export const settings = 'https://s3.21bits.io/front_static/assets/images/svgIcon/Settings_icon.svg';
export const settings_blue = 'https://s3.21bits.io/front_static/assets/images/svgIcon/Settings_icon-1.svg';
export const profile_blue = 'https://s3.21bits.io/front_static/assets/images/svgIcon/Profile_icon_blue.svg';
export const arrow_icon = 'https://s3.21bits.io/front_static/assets/images/svgIcon/arrow_icon.svg';
export const arrow_icon_blue = 'https://s3.21bits.io/front_static/assets/images/svgIcon/arrow_icon_blue.svg';

export const collClose = 'https://s3.21bits.io/front_static/assets/images/svgIcon/Component_1.svg';
export const collOpen = 'https://s3.21bits.io/front_static/assets/images/svgIcon/Component_6.svg';

export const bonus_yellow = 'https://s3.21bits.io/front_static/assets/images/svgIcon/bonus_on.svg';

export const favorites = 'https://s3.21bits.io/front_static/assets/images/svgIcon/Favorites_icon.svg';
export const favorites_blue = 'https://s3.21bits.io/front_static/assets/images/svgIcon/Favorites_icon_on.svg';
export const favorites_pink = 'https://s3.21bits.io/front_static/assets/images/newIcon/favorites_game_up.png';

export const liveCasino = 'https://s3.21bits.io/front_static/assets/images/svgIcon/Live_Casino_icon.svg';
export const liveCasino_blue = 'https://s3.21bits.io/front_static/assets/images/svgIcon/Live_Casino_icon_on.svg';

export const Wallet = 'https://s3.21bits.io/front_static/assets/images/svgIcon/Cashier_icon.svg';
export const Wallet_blue = 'https://s3.21bits.io/front_static/assets/images/svgIcon/Cashier_icon_on.svg';
export const VIP = 'https://s3.21bits.io/front_static/assets/images/svgIcon/VIP_icon.svg';
export const VIP_blue = 'https://s3.21bits.io/front_static/assets/images/svgIcon/VIP_icon-1.svg';
export const referrals = 'https://s3.21bits.io/front_static/assets/images/svgIcon/Referrals_icon.svg';
export const referrals_blue = 'https://s3.21bits.io/front_static/assets/images/svgIcon/Referrals_icon-1.svg';

export const esports = 'https://s3.21bits.io/front_static/assets/images/svgIcon/Esport_icon.svg';
export const esports_blue = 'https://s3.21bits.io/front_static/assets/images/svgIcon/Esport_icon-1.svg';
export const Sport2_blue = 'https://s3.21bits.io/front_static/assets/images/svgIcon/Sport_icon-2.svg';
export const Sport2 = 'https://s3.21bits.io/front_static/assets/images/svgIcon/Sport_icon-3.svg';

export const slot = 'https://s3.21bits.io/front_static/assets/images/svgIcon/Slot_icon-3.svg';
export const slot_yellow = 'https://s3.21bits.io/front_static/assets/images/svgIcon/Slot_icon-2.svg';
export const slot_blue = 'https://s3.21bits.io/front_static/assets/images/svgIcon/Slot_icon-1.svg';

export const Lc_BJ = 'https://s3.21bits.io/front_static/assets/images/banner/Lc_BJ.webp';
export const liveCasino_yellow = 'https://s3.21bits.io/front_static/assets/images/svgIcon/Live_Casino_icon_light.svg';

export const gameShow = 'https://s3.21bits.io/front_static/assets/images/svgIcon/Virtual_Sport_icon.svg';
export const gameShow_yellow = 'https://s3.21bits.io/front_static/assets/images/svgIcon/Virtual_Sport_icon-2.svg';
export const gameShow_blue = 'https://s3.21bits.io/front_static/assets/images/svgIcon/Virtual_Sport_icon-1.svg';

export const blackjack = 'https://s3.21bits.io/front_static/assets/images/svgIcon/Blackjack_icon.svg';
export const blackjack_yellow = 'https://s3.21bits.io/front_static/assets/images/svgIcon/Blackjack_icon_light.svg';
export const blackjack_blue = 'https://s3.21bits.io/front_static/assets/images/svgIcon/Blackjack_icon_on.svg';

export const roulette = 'https://s3.21bits.io/front_static/assets/images/svgIcon/Roulette_icon.svg';
export const roulette_blue = 'https://s3.21bits.io/front_static/assets/images/svgIcon/Roulette_icon-1.svg';
export const roulette_yellow = 'https://s3.21bits.io/front_static/assets/images/svgIcon/Roulette_icon-2.svg';

export const Baccarat = 'https://s3.21bits.io/front_static/assets/images/svgIcon/Baccarat_icon_on.svg';
export const Baccarat_yellow = 'https://s3.21bits.io/front_static/assets/images/svgIcon/Baccarat_icon_light.svg';
export const Baccarat_blue = 'https://s3.21bits.io/front_static/assets/images/svgIcon/Baccarat_icon-1.svg';

export const Bits = 'https://s3.21bits.io/front_static/assets/images/svgIcon/21bits_icon.svg';
export const Bits_yellow = 'https://s3.21bits.io/front_static/assets/images/svgIcon/21bits_icon_light.svg';
export const Bits_blue = 'https://s3.21bits.io/front_static/assets/images/svgIcon/21bits_icon_on.svg';

export const favorites_yellow = 'https://s3.21bits.io/front_static/assets/images/svgIcon/Favorites_icon_light.svg';

export const challenge = 'https://s3.21bits.io/front_static/assets/images/newIcon/challenge.png';
export const challenge_yellow = 'https://s3.21bits.io/front_static/assets/images/newIcon/challenge_yellow.png';
export const challenge_blue = 'https://s3.21bits.io/front_static/assets/images/newIcon/challenge_on.png';

export const updown = 'https://s3.21bits.io/front_static/assets/images/svgIcon/updown.svg';
export const updown_blue = 'https://s3.21bits.io/front_static/assets/images/svgIcon/updown_on.svg';
export const crypto_yellow = 'https://s3.21bits.io/front_static/assets/images/svgIcon/Crypto_on.svg';
export const promotions_yellow = 'https://s3.21bits.io/front_static/assets/images/svgIcon/promotions_on.svg';

export const deposits = 'https://s3.21bits.io/front_static/assets/images/newIcon/Deposite.png';
export const deposits_yellow = 'https://s3.21bits.io/front_static/assets/images/newIcon/Deposite_on.png';
export const withdraw = 'https://s3.21bits.io/front_static/assets/images/newIcon/Withdraw.png';
export const withdraw_yellow = 'https://s3.21bits.io/front_static/assets/images/newIcon/Withdraw_on.png';
export const coupon = 'https://s3.21bits.io/front_static/assets/images/newIcon/Coupon.png';
export const coupon_yellow = 'https://s3.21bits.io/front_static/assets/images/newIcon/Coupon_on.png';
export const Vector_yellow = 'https://s3.21bits.io/front_static/assets/images/svgIcon/Referrals_icon-2.svg';
export const browse_off = 'https://s3.21bits.io/front_static/assets/images/svgIcon/browse_off.svg';
export const browse_on = 'https://s3.21bits.io/front_static/assets/images/svgIcon/browse_on.svg';
export const search_icon = 'https://s3.21bits.io/front_static/assets/images/svgIcon/search_icon.svg';

export const VIP_yellow = 'https://s3.21bits.io/front_static/assets/images/svgIcon/VIP_icon-2.svg';
export const transactions_yellow = 'https://s3.21bits.io/front_static/assets/images/svgIcon/Transactions_icon-2.svg';
export const settings_yellow = 'https://s3.21bits.io/front_static/assets/images/svgIcon/Settings_icon-2.svg';

export const Visa = 'https://s3.21bits.io/front_static/assets/images/web/Visa.png';
export const applepay = 'https://s3.21bits.io/front_static/assets/images/web/applepay.png';
export const Googlepay = 'https://s3.21bits.io/front_static/assets/images/web/Googlepay.png';
export const Mastercard = 'https://s3.21bits.io/front_static/assets/images/web/Mastercard.png';

export const box_unable = 'https://s3.21bits.io/front_static/assets/images/claim/Box_unavaileble.png';
export const box1_unable = 'https://s3.21bits.io/front_static/assets/images/claim/Box1_unclaimed.png';
export const box1_able = 'https://s3.21bits.io/front_static/assets/images/claim/Box1_claimed.png';
export const box2_unable = 'https://s3.21bits.io/front_static/assets/images/claim/Box2_unclaimed.png';
export const box2_able = 'https://s3.21bits.io/front_static/assets/images/claim/Box2_claimed.png';
export const box3_unable = 'https://s3.21bits.io/front_static/assets/images/claim/Box3_unclaimed.png';
export const box3_able = 'https://s3.21bits.io/front_static/assets/images/claim/Box3_claimed.png';
export const box4_unable = 'https://s3.21bits.io/front_static/assets/images/claim/Box4_unclaimed.png';
export const box4_able = 'https://s3.21bits.io/front_static/assets/images/claim/Box4_claimed.png';
export const Lock_icon = 'https://s3.21bits.io/front_static/assets/images/newIcon/Lock_icon.png';

// export const EmptSlot = 'https://s3.21bits.io/front_static/assets/images/home/21BITS_slot.png';
export const EmptSlot = 'https://s3.21bits.io/front_static/assets/images/home/21BITS_slot.webp';
export const up_dir = 'https://s3.21bits.io/front_static/assets/images/web/up_dir.png';
export const down_dir = 'https://s3.21bits.io/front_static/assets/images/web/down_dir.png';
export const provablyFair = 'https://s3.21bits.io/front_static/assets/images/web/provablyFair.svg';
export const what = 'https://s3.21bits.io/front_static/assets/images/web/what.png';

export const ICON1 = 'https://s3.21bits.io/front_static/assets/images/wallet/coupons_icon.png';
export const ICON2 = 'https://s3.21bits.io/front_static/assets/images/wallet/referrals_icon.png';

export const Go_back = 'https://s3.21bits.io/front_static/assets/images/wallet/go_back.png';
export const DOLLAR = 'https://s3.21bits.io/front_static/assets/images/wallet/dollar_icon.png';
export const VECTOR = 'https://s3.21bits.io/front_static/assets/images/wallet/vector.png';
export const OTHER_1 = 'https://s3.21bits.io/front_static/assets/images/wallet/other_1.png';
export const OTHER_2 = 'https://s3.21bits.io/front_static/assets/images/wallet/other_2.png';
export const OTHER_3 = 'https://s3.21bits.io/front_static/assets/images/wallet/other_3.png';
export const OTHER_4 = 'https://s3.21bits.io/front_static/assets/images/wallet/other_4.png';
export const COPY = 'https://s3.21bits.io/front_static/assets/images/wallet/copy_icon.png';

export const choreplay = 'https://s3.21bits.io/front_static/assets/images/users/choreplay.svg';
export const macos = 'https://s3.21bits.io/front_static/assets/images/users/macos.svg';
export const replay = 'https://s3.21bits.io/front_static/assets/images/users/replay.svg';

export const CHAINBITS = 'https://s3.21bits.io/front_static/assets/images/wallet/CHAINBITS.png';
export const MoonPay = 'https://s3.21bits.io/front_static/assets/images/wallet/MoonPay.png';
export const paybis = 'https://s3.21bits.io/front_static/assets/images/wallet/paybis.png';

export const Unlock_icon = 'https://s3.21bits.io/front_static/assets/images/claim/Unlock_icon.png';
export const load_bg = 'https://s3.21bits.io/front_static/assets/images/home/load_bg.png';

export const gif = 'https://s3.21bits.io/front_static/assets/images/gif/Loading.gif';

export const loginOn = 'https://s3.21bits.io/front_static/assets/images/newIcon/Component_3.png';
export const loginOff = 'https://s3.21bits.io/front_static/assets/images/newIcon/Component_5.png';
export const resiginOff = 'https://s3.21bits.io/front_static/assets/images/newIcon/Component_4.png';
export const resiginOn = 'https://s3.21bits.io/front_static/assets/images/newIcon/Component_2.png';
export const loginVerify_down = 'https://s3.21bits.io/front_static/assets/images/loginVerify/down.svg';

export const left_arrow = 'https://s3.21bits.io/front_static/assets/images/newIcon/left_arrow.png';
export const left_arrow_blue = 'https://s3.21bits.io/front_static/assets/images/newIcon/left_arrow_blue.png';
export const right_arrow = 'https://s3.21bits.io/front_static/assets/images/newIcon/right_arrow.png';
export const right_arrow_blue = 'https://s3.21bits.io/front_static/assets/images/newIcon/right_arrow_blue.png';

export const Bets = 'https://s3.21bits.io/front_static/assets/images/newIcon/Bet_icon.png';
export const ppBorder = 'https://s3.21bits.io/front_static/assets/images/home/ppBorder.png';

// level
export const Unranked = 'https://s3.21bits.io/front_static/assets/images/vip_level/Unranked.png';
export const Bronze_1 = 'https://s3.21bits.io/front_static/assets/images/vip_level/Bronze_1.png';
export const Bronze_2 = 'https://s3.21bits.io/front_static/assets/images/vip_level/Bronze_2.png';
export const Bronze_3 = 'https://s3.21bits.io/front_static/assets/images/vip_level/Bronze_3.png';
export const Bronze_4 = 'https://s3.21bits.io/front_static/assets/images/vip_level/Bronze_4.png';
export const Bronze_5 = 'https://s3.21bits.io/front_static/assets/images/vip_level/Bronze_5.png';

export const Diamond_1 = 'https://s3.21bits.io/front_static/assets/images/vip_level/Diamond_1.png';
export const Diamond_2 = 'https://s3.21bits.io/front_static/assets/images/vip_level/Diamond_2.png';
export const Diamond_3 = 'https://s3.21bits.io/front_static/assets/images/vip_level/Diamond_3.png';
export const Diamond_4 = 'https://s3.21bits.io/front_static/assets/images/vip_level/Diamond_4.png';
export const Diamond_5 = 'https://s3.21bits.io/front_static/assets/images/vip_level/Diamond_5.png';

export const Gold_1 = 'https://s3.21bits.io/front_static/assets/images/vip_level/Gold_1.png';
export const Gold_2 = 'https://s3.21bits.io/front_static/assets/images/vip_level/Gold_2.png';
export const Gold_3 = 'https://s3.21bits.io/front_static/assets/images/vip_level/Gold_3.png';
export const Gold_4 = 'https://s3.21bits.io/front_static/assets/images/vip_level/Gold_4.png';
export const Gold_5 = 'https://s3.21bits.io/front_static/assets/images/vip_level/Gold_5.png';

export const Platinum_1 = 'https://s3.21bits.io/front_static/assets/images/vip_level/Platinum_1.png';
export const Platinum_2 = 'https://s3.21bits.io/front_static/assets/images/vip_level/Platinum_2.png';
export const Platinum_3 = 'https://s3.21bits.io/front_static/assets/images/vip_level/Platinum_3.png';
export const Platinum_4 = 'https://s3.21bits.io/front_static/assets/images/vip_level/Platinum_4.png';
export const Platinum_5 = 'https://s3.21bits.io/front_static/assets/images/vip_level/Platinum_5.png';

export const Silver_1 = 'https://s3.21bits.io/front_static/assets/images/vip_level/Silver_1.png';
export const Silver_2 = 'https://s3.21bits.io/front_static/assets/images/vip_level/Silver_2.png';
export const Silver_3 = 'https://s3.21bits.io/front_static/assets/images/vip_level/Silver_3.png';
export const Silver_4 = 'https://s3.21bits.io/front_static/assets/images/vip_level/Silver_4.png';
export const Silver_5 = 'https://s3.21bits.io/front_static/assets/images/vip_level/Silver_5.png';

export const Orichalcum = 'https://s3.21bits.io/front_static/assets/images/vip_level/Orichalcum.png';
export const Mithril = 'https://s3.21bits.io/front_static/assets/images/vip_level/Mithril.png';

export const airdropImg = 'https://s3.21bits.io/front_static/assets/images/home/Banner_Airdrop.png';

export const next = 'https://s3.21bits.io/front_static/assets/images/web/next.png';
export const backIcon = 'https://s3.21bits.io/front_static/assets/images/web/back.png';
export const vctor = 'https://s3.21bits.io/front_static/assets/images/web/vctor.png';
export const up = 'https://s3.21bits.io/front_static/assets/images/web/up.png';
export const down = 'https://s3.21bits.io/front_static/assets/images/web/down.png';

export const leaderBoard_on = 'https://s3.21bits.io/front_static/assets/images/web/leaderBoard_on.png';
export const leaderBoard_off = 'https://s3.21bits.io/front_static/assets/images/web/leaderBoard_off.png';
export const prediction_off = 'https://s3.21bits.io/front_static/assets/images/web/prediction_off.png';
export const prediction_on = 'https://s3.21bits.io/front_static/assets/images/web/prediction_on.png';
export const history_off = 'https://s3.21bits.io/front_static/assets/images/web/history_off.png';
export const history_on = 'https://s3.21bits.io/front_static/assets/images/web/history_on.png';
export const time = 'https://s3.21bits.io/front_static/assets/images/web/time.png';
export const timeLater = 'https://s3.21bits.io/front_static/assets/images/web/timeLater.png';
export const chainlink = 'https://s3.21bits.io/front_static/assets/images/web/powered-by-chainlink.svg';
export const Roll_Section = 'https://s3.21bits.io/front_static/assets/images/web/Roll_Section.png';

export const VectorOne = 'https://s3.21bits.io/front_static/assets/images/web/VectorOne.png';
export const RoundsWin = 'https://s3.21bits.io/front_static/assets/images/web/RoundsWin.png';
export const result = 'https://s3.21bits.io/front_static/assets/images/web/result.png';

export const web_up = 'https://s3.21bits.io/front_static/assets/images/web/up_1.png';
export const web_down = 'https://s3.21bits.io/front_static/assets/images/web/down_1.png';
export const vector_yellow = 'https://s3.21bits.io/front_static/assets/images/web/vector_yellow.png';

export const blue_box = 'https://s3.21bits.io/front_static/assets/images/claim/blue_box.png';
export const red_box = 'https://s3.21bits.io/front_static/assets/images/claim/red_box.png';
export const green_box = 'https://s3.21bits.io/front_static/assets/images/claim/green_box.png';
export const yellow_box = 'https://s3.21bits.io/front_static/assets/images/claim/yellow_box.png';
export const tips = 'https://s3.21bits.io/front_static/assets/images/newIcon/info_icon.png';
export const tips_blue = 'https://s3.21bits.io/front_static/assets/images/newIcon/info_blue.png';
export const green_icon = 'https://s3.21bits.io/front_static/assets/images/newIcon/green_icon.png';
export const red_icon = 'https://s3.21bits.io/front_static/assets/images/newIcon/error_icon.png';
export const coin_icon = 'https://s3.21bits.io/front_static/assets/images/newIcon/Coin.png';
export const get_now = 'https://s3.21bits.io/front_static/assets/images/newIcon/charge.png';

export const { useSnackbar } = 'notistack';

export const bonus_1 = 'https://s3.21bits.io/front_static/assets/images/claim/bonus1.png';
export const bonus_2 = 'https://s3.21bits.io/front_static/assets/images/claim/bonus2.png';
export const bonus_3 = 'https://s3.21bits.io/front_static/assets/images/claim/bonus3.png';
export const bonus_4 = 'https://s3.21bits.io/front_static/assets/images/claim/bonus4.png';

export const liveUpSvg = 'https://s3.21bits.io/front_static/assets/images/bouns/live_up.svg';
export const recharge = 'https://s3.21bits.io/front_static/assets/images/bouns/recharge.svg';
export const weekly = 'https://s3.21bits.io/front_static/assets/images/bouns/weekly.svg';
export const b1 = 'https://s3.21bits.io/front_static/assets/images/bouns/b1.webp';
export const b2 = 'https://s3.21bits.io/front_static/assets/images/bouns/b2.webp';
export const b3 = 'https://s3.21bits.io/front_static/assets/images/bouns/b3.webp';
export const b4 = 'https://s3.21bits.io/front_static/assets/images/bouns/b4.webp';
export const loading = 'https://s3.21bits.io/front_static/assets/images/trade/Loading.gif';

export const betBg = 'https://s3.21bits.io/front_static/assets/images/plinko/betBg.png';
export const ball_btn_default = 'https://s3.21bits.io/front_static/assets/images/plinko/ball_btn_default.png';

export const triangle = 'https://s3.21bits.io/front_static/assets/images/plinko/triangle.png';
export const HistoryBg = 'https://s3.21bits.io/front_static/assets/images/plinko/History.png';
export const banner3 = 'https://s3.21bits.io/front_static/assets/images/banner/banner_sport.webp';
export const PPgame_Banner = 'https://s3.21bits.io/front_static/assets/images/banner/PPgame_Banner.webp';

// export const Place_icon_on = 'https://s3.21bits.io/front_static/assets/images/newIcon/Place_icon_on.png';
// export const bitsGames = 'https://s3.21bits.io/front_static/assets/images/newIcon/21bits_icon_on.png';
// export const slotGames = 'https://s3.21bits.io/front_static/assets/images/newIcon/Slot_icon_on.png';
// export const VrtualGames = 'https://s3.21bits.io/front_static/assets/images/newIcon/Virtual_Sport_icon_on.png';
// export const BlackjackGames = 'https://s3.21bits.io/front_static/assets/images/newIcon/Blackjack_icon_on.png';
// export const RouletteGames = 'https://s3.21bits.io/front_static/assets/images/newIcon/Roulette_icon_on.png';
// export const BaccaratGames = 'https://s3.21bits.io/front_static/assets/images/newIcon/Baccarat_icon_on.png';

export const bnbLogo = 'https://s3.21bits.io/front_static/assets/images/web/BNB.png';
export const Polygon = 'https://s3.21bits.io/front_static/assets/images/web/Polygon.png';
export const disable = 'https://s3.21bits.io/front_static/assets/images/web/disable.png';
export const AddressIcon = 'https://s3.21bits.io/front_static/assets/images/web/Address.png';
export const VectorThree = 'https://s3.21bits.io/front_static/assets/images/web/VectorThree.png';
export const PayOutIcon = 'https://s3.21bits.io/front_static/assets/images/web/PayOutIcon.png';
export const myPrizeOff = 'https://s3.21bits.io/front_static/assets/images/web/myPrizeOff.png';
export const myPrizeOn = 'https://s3.21bits.io/front_static/assets/images/web/myPrizeOn.png';
export const vectorOff = 'https://s3.21bits.io/front_static/assets/images/web/VectorOff.png';
export const vectorOn = 'https://s3.21bits.io/front_static/assets/images/web/VectorOn.png';

export const winRate = 'https://s3.21bits.io/front_static/assets/images/web/winRate.png';
export const netWin = 'https://s3.21bits.io/front_static/assets/images/web/netWin.png';
export const roundsPlayed = 'https://s3.21bits.io/front_static/assets/images/web/roundsPlayed.png';
export const user = 'https://s3.21bits.io/front_static/assets/images/web/user.png';
export const nocie = 'https://s3.21bits.io/front_static/assets/images/web/nocie.svg';

export const refferrals = 'https://s3.21bits.io/front_static/assets/images/svgIcon/Referrals_icon.svg';

export const profile = 'https://s3.21bits.io/front_static/assets/images/svgIcon/Component_5.svg';
export const profile_yellow = 'https://s3.21bits.io/front_static/assets/images/svgIcon/Component_3.svg';

export const reffferrals_bg3 = 'https://s3.21bits.io/front_static/assets/images/profile/reffferrals_bg3.png';
export const reffferrals_bg = 'https://s3.21bits.io/front_static/assets/images/profile/reffferrals_bg.png';
export const refferal_code = 'https://s3.21bits.io/front_static/assets/images/newIcon/Referral_Codes.png';
export const refferal_code_off = 'https://s3.21bits.io/front_static/assets/images/newIcon/Referral_Codes_off.png';
export const rafferUser_on = 'https://s3.21bits.io/front_static/assets/images/svgIcon/Profile_icon_blue.svg';
export const rafferUser_off = 'https://s3.21bits.io/front_static/assets/images/svgIcon/Component_5.svg';
export const copyLink = 'https://s3.21bits.io/front_static/assets/images/newIcon/link_blue.png';
export const wagered_cion = 'https://s3.21bits.io/front_static/assets/images/profile/wagered_cion.png';
export const complete = 'https://s3.21bits.io/front_static/assets/images/newIcon/Check_btn.png';
export const pending = 'https://s3.21bits.io/front_static/assets/images/newIcon/Check_btn_1.png';

export const pedding = 'https://s3.21bits.io/front_static/assets/images/profile/pedding.png';
export const success = 'https://s3.21bits.io/front_static/assets/images/newIcon/Check_btn.png';
export const reset = 'https://s3.21bits.io/front_static/assets/images/newIcon/reset.svg';

export const deposits_on = 'https://s3.21bits.io/front_static/assets/images/newIcon/Deposit_on.png';
export const deposits_off = 'https://s3.21bits.io/front_static/assets/images/newIcon/Deposit_icon.png';
export const withdawals_off = 'https://s3.21bits.io/front_static/assets/images/newIcon/Withdraw.png';
export const withdawals_on = 'https://s3.21bits.io/front_static/assets/images/newIcon/Withdraw_blue.png';
export const bet_on = 'https://s3.21bits.io/front_static/assets/images/newIcon/Bet_icon.png';
export const bet_off = 'https://s3.21bits.io/front_static/assets/images/newIcon/Bet_icon_off.png';

export const email_icon = 'https://s3.21bits.io/front_static/assets/images/newIcon/Email_icon.png';
export const email_icon_on = 'https://s3.21bits.io/front_static/assets/images/newIcon/Email_icon_on.png';

export const deposit = 'https://s3.21bits.io/front_static/assets/images/newIcon/Deposit_icon.png';
export const deposit_on = 'https://s3.21bits.io/front_static/assets/images/newIcon/Deposit_on.png';
export const bte = 'https://s3.21bits.io/front_static/assets/images/newIcon/Place_icon.png';
export const bte_on = 'https://s3.21bits.io/front_static/assets/images/newIcon/Place_icon_on.png';

export const bgImage_1 = 'https://s3.21bits.io/front_static/assets/images/promotions/bgImage_1.webp';
export const bgImage_2 = 'https://s3.21bits.io/front_static/assets/images/promotions/bgImage_2.webp';
export const bgImage_3 = 'https://s3.21bits.io/front_static/assets/images/promotions/bgImage_3.webp';
export const bgImage_4 = 'https://s3.21bits.io/front_static/assets/images/promotions/bgImage_4.webp';
export const glow = 'https://s3.21bits.io/front_static/assets/images/promotions/glow.webp';
export const cube_1 = 'https://s3.21bits.io/front_static/assets/images/promotions/cube-1.png';
export const cube_2 = 'https://s3.21bits.io/front_static/assets/images/promotions/cube-2.png';
export const cube_3 = 'https://s3.21bits.io/front_static/assets/images/promotions/cube-3.png';
export const cube_4 = 'https://s3.21bits.io/front_static/assets/images/promotions/cube-4.png';
export const right_white = 'https://s3.21bits.io/front_static/assets/images/promotions/right.svg';

export const PPgame_banner3 = 'https://s3.21bits.io/front_static/assets/images/promotions/PPgame_Banner_ss.webp';
export const banner = 'https://s3.21bits.io/front_static/assets/images/banner/PPgame_Banner.webp';
export const back = 'https://s3.21bits.io/front_static/assets/images/svgIcon/back.svg';

export const raffleBanner = 'https://s3.21bits.io/front_static/assets/images/raffle/banner.png';
export const ball1 = 'https://s3.21bits.io/front_static/assets/images/raffle/ball1.png';
export const ball2 = 'https://s3.21bits.io/front_static/assets/images/raffle/ball2.png';
export const ball3 = 'https://s3.21bits.io/front_static/assets/images/raffle/ball3.png';
export const ball4 = 'https://s3.21bits.io/front_static/assets/images/raffle/ball4.png';
export const ball5 = 'https://s3.21bits.io/front_static/assets/images/raffle/ball5.png';
export const ball6 = 'https://s3.21bits.io/front_static/assets/images/raffle/ball6.png';

export const right = 'https://s3.21bits.io/front_static/assets/images/raffle/right.png';
export const rightOn = 'https://s3.21bits.io/front_static/assets/images/raffle/right_on.png';
export const left = 'https://s3.21bits.io/front_static/assets/images/raffle/left.png';
export const leftOn = 'https://s3.21bits.io/front_static/assets/images/raffle/left_on.png';
export const allRight = 'https://s3.21bits.io/front_static/assets/images/raffle/all_right.png';
export const allRightOn = 'https://s3.21bits.io/front_static/assets/images/raffle/all_right_on.png';

export const criteria = 'https://s3.21bits.io/front_static/assets/images/raffle/criteria.png';
export const funds = 'https://s3.21bits.io/front_static/assets/images/raffle/funds.png';

export const StartIcon = 'https://s3.21bits.io/front_static/assets/images/trade/start_icon.png';
export const Finish_icon = 'https://s3.21bits.io/front_static/assets/images/trade/finish_icon.png';

export const up_box = 'https://s3.21bits.io/front_static/assets/images/trade/up_box.png';
export const down_box = 'https://s3.21bits.io/front_static/assets/images/trade/down_box.png';
export const upper_ui = 'https://s3.21bits.io/front_static/assets/images/trade/upper_ui.png';
export const down_history = 'https://s3.21bits.io/front_static/assets/images/trade/down_history.png';
export const up_history = 'https://s3.21bits.io/front_static/assets/images/trade/up_history.png';
export const coinWin = 'https://s3.21bits.io/front_static/assets/images/trade/coinWin.png';
export const coinWinBg = 'https://s3.21bits.io/front_static/assets/images/trade/coinWinBg.png';

export const History_icon = 'https://s3.21bits.io/front_static/assets/images/trade/History_icon.png';
export const info_icon = 'https://s3.21bits.io/front_static/assets/images/trade/info_icon.png';
export const Down_icon = 'https://s3.21bits.io/front_static/assets/images/trade/Down_icon.png';
export const up_icon = 'https://s3.21bits.io/front_static/assets/images/trade/up_icon.png';
export const USDT_icon = 'https://s3.21bits.io/front_static/assets/images/trade/USDT_icon.png';
export const down_default = 'https://s3.21bits.io/front_static/assets/images/trade/down_default.png';

export const progressDemo = 'https://s3.21bits.io/front_static/assets/images/trade/progressDemo.png';
export const btnDemo = 'https://s3.21bits.io/front_static/assets/images/trade/btnDemo.png';
export const btnDemo1 = 'https://s3.21bits.io/front_static/assets/images/trade/btnDemo1.png';
export const betCardDemo = 'https://s3.21bits.io/front_static/assets/images/trade/betCardDemo.png';
export const pageDemo = 'https://s3.21bits.io/front_static/assets/images/trade/pageDemo.png';
export const allPageDemo = 'https://s3.21bits.io/front_static/assets/images/trade/allPageDemo.png';
export const processBg = 'https://s3.21bits.io/front_static/assets/images/trade/processBg.png';

export const Hive_R = 'https://s3.21bits.io/front_static/assets/images/plinko/Hive_R.png';
export const Hive_B = 'https://s3.21bits.io/front_static/assets/images/plinko/Hive_B.png';
export const Hive_L = 'https://s3.21bits.io/front_static/assets/images/plinko/Hive_L.png';
export const ballBg = 'https://s3.21bits.io/front_static/assets/images/plinko/ball.png';


export const rebits = 'https://s3.21bits.io/front_static/assets/images/web/bluecase.webp';
export const Salary = 'https://s3.21bits.io/front_static/assets/images/web/yellowcase.webp';
export const vipRebits = 'https://s3.21bits.io/front_static/assets/images/web/vipRebits.webp';
export const vipBouns = 'https://s3.21bits.io/front_static/assets/images/web/vipBouns.webp';
export const calendar = 'https://s3.21bits.io/front_static/assets/images/web/calendar.svg';
// export const statistic = 'https://s3.21bits.io/front_static/assets/images/statistic/statistic.svg';
export const statistic = 'https://s3.ap-southeast-1.amazonaws.com/s3.21bits.io/front_static/assets/images/statistic/statistic.svg';
export const statistic_green = 'https://s3.21bits.io/front_static/assets/images/statistic/statistic_green.svg';
export const statistic_yellow = 'https://s3.21bits.io/front_static/assets/images/statistic/statistics_yellow.svg';
export const statistic_blue = 'https://s3.21bits.io/front_static/assets/images/statistic/statistics_blue.svg';
export const trophies = 'https://s3.21bits.io/front_static/assets/images/statistic/trophies.svg';
export const trophies_green = 'https://s3.21bits.io/front_static/assets/images/statistic/trophies_green.svg';
export const trophies_yellow = 'https://s3.21bits.io/front_static/assets/images/statistic/trophies_yellow.svg';
export const statistic_0 = 'https://s3.21bits.io/front_static/assets/images/statistic/statistic_0.png';
export const download = 'https://s3.21bits.io/front_static/assets/images/statistic/down.svg';
export const download_green = 'https://s3.21bits.io/front_static/assets/images/statistic/down_green.svg';
export const download_yellow = 'https://s3.21bits.io/front_static/assets/images/statistic/down_blue.svg';
export const Withdraw_vault_on = 'https://s3.21bits.io/front_static/assets/images/statistic/Withdraw_vault_on.svg';
export const Withdraw_vault_off = 'https://s3.21bits.io/front_static/assets/images/statistic/Withdraw_vault_off.svg';
export const Deposit_vault_on = 'https://s3.21bits.io/front_static/assets/images/statistic/Deposit_vault_on.svg';
export const Deposit_vault_off = 'https://s3.21bits.io/front_static/assets/images/statistic/Deposit_vault_off.svg';
export const Withdraw_vault_green = 'https://s3.21bits.io/front_static/assets/images/statistic/Withdraw_vault_green.svg';
export const valutMenu = 'https://s3.21bits.io/front_static/assets/images/statistic/valutMenu.svg';
export const valutMenuBlue = 'https://s3.21bits.io/front_static/assets/images/statistic/valutMenuBlue.svg';
export const gitBlue = 'https://s3.21bits.io/front_static/assets/images/svgIcon/gitBlue.svg';
export const group = 'https://s3.21bits.io/front_static/assets/images/svgIcon/group.svg';
export const promotions = 'https://s3.21bits.io/front_static/assets/images/svgIcon/promotions.svg';
export const tip = 'https://s3.21bits.io/front_static/assets/images/statistic/tip.svg';
export const tip_yellow = 'https://s3.21bits.io/front_static/assets/images/statistic/tip_yellow.svg';
export const tip_blue = 'https://s3.21bits.io/front_static/assets/images/statistic/tip_blue.svg';
export const ppSidemark = 'https://s3.ap-southeast-1.amazonaws.com/s3.21bits.io/ppActive/image/ppSidemark.webp';
export const DropsWinsActive = 'https://s3.ap-southeast-1.amazonaws.com/s3.21bits.io/ppActive/image/DropsWins_banner500x240.webp';
export const DropsWinsBanner = 'https://s3.ap-southeast-1.amazonaws.com/s3.21bits.io/ppActive/image/DropsWins_banner1500x600.webp';
export const SnakesLaddersBanner = 'https://s3.ap-southeast-1.amazonaws.com/s3.21bits.io/ppActive/image/SnakesLaddersLiveMadness11500_600.webp';
export const SnakesLaddersActive = 'https://s3.ap-southeast-1.amazonaws.com/s3.21bits.io/ppActive/image/SnakesLaddersLiveMadness500x240.webp';
export const BetOff = 'https://s3.21bits.io/front_static/assets/images/transations/BetOff.svg';
export const BetOn = 'https://s3.21bits.io/front_static/assets/images/transations/BetOn.svg';
export const BonusOff = 'https://s3.21bits.io/front_static/assets/images/transations/BonusOff.svg';
export const BonusOn = 'https://s3.21bits.io/front_static/assets/images/transations/BonusOn.svg';
export const Confirm = 'https://s3.21bits.io/front_static/assets/images/transations/Confirm.svg';
export const DepositeOff = 'https://s3.21bits.io/front_static/assets/images/transations/DepositeOff.svg';
export const DepositeOn = 'https://s3.21bits.io/front_static/assets/images/transations/DepositeOn.svg';
export const Link = 'https://s3.21bits.io/front_static/assets/images/transations/Link.svg';
export const OtherOff = 'https://s3.21bits.io/front_static/assets/images/transations/OtherOff.svg';
export const OtherOn = 'https://s3.21bits.io/front_static/assets/images/transations/OtherOn.svg';
export const Pending = 'https://s3.21bits.io/front_static/assets/images/transations/Pending.svg';
export const TipsOff = 'https://s3.21bits.io/front_static/assets/images/transations/TipsOff.svg';
export const TipsOn = 'https://s3.21bits.io/front_static/assets/images/transations/TipsOn.svg';
export const ValutOff = 'https://s3.21bits.io/front_static/assets/images/transations/ValutOff.svg';
export const ValutOn = 'https://s3.21bits.io/front_static/assets/images/transations/ValutOn.svg';
export const VIPBonusOff = 'https://s3.21bits.io/front_static/assets/images/transations/VIPBonusOff.svg';
export const VIPBonusOn = 'https://s3.21bits.io/front_static/assets/images/transations/VIPBonusOn.svg';
export const WithdrawOff = 'https://s3.21bits.io/front_static/assets/images/transations/WithdrawOff.svg';
export const WithdrawOn = 'https://s3.21bits.io/front_static/assets/images/transations/WithdrawOn.svg';
export const OctoAttackActive = 'https://s3.ap-southeast-1.amazonaws.com/s3.21bits.io/ppActive/image/OctoAttack_500x240_banner.webp';
export const OctoAttackBanner = 'https://s3.ap-southeast-1.amazonaws.com/s3.21bits.io/ppActive/image/OctoAttack_500x1600_banner.webp';
export const depositActive = 'https://s3.21bits.io/front_static/assets/images/banner/depositActive.webp';
